.question-container {
  margin-top: 24px;
}

.question-title {
  font-size: 18px;
  line-height: 150%;
}

.question-input {
  margin-top: 24px;
  padding: 12px 18px;
  background: #091624;
  border: 2px solid rgba(255, 255, 255, 0.18);
  width: 360px;
  max-width: calc(100% - 48px);
  border-radius: 8px;
  outline: none;
  font-size: 18px;
  color: white;
  box-sizing: border-box;
}

.buttons-container {
  display: flex;
  margin-top: 24px;
  flex-wrap: wrap;
  width: 360px;
  max-width: calc(100% - 48px);
}

.button {
  flex: 1;
  margin-top: 18px;
}

p {
  line-height: 150%;
  color: #C4C7D3;
}

.text-red-gradient {
  background: linear-gradient(135deg, #FF3D3D 0%, #FFA450 100%);;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}

.text-green-gradient {
  background: linear-gradient(135deg, #92FF3D 0%, #50C0FF 100%);;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}

a {
  text-decoration: none;
}