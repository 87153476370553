.button {
  padding: 18px 24px;
  font-size: 18px;
  border: 0;
  cursor: pointer;
  transition: 0.2s;
  color: white;
  border-radius: 4px;
  background: linear-gradient(135deg, #3DDCFF 0%, #D150FF 100%);
}

.button:hover {
  opacity: 0.6;
  transition: 0.2s;
}

.button:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}